
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.documents {
  .esmp-table {
    width: 100%;
    &__thead-tr,
    &__tbody-tr {
      display: grid;
      grid-template-columns: 50px 1fr 1fr 75px 150px;
    }
    &__thead-th {
      .esmp-table__thead-th-divider {
        display: none;
      }
      &:first-child,
      &:nth-child(4) {
        .esmp-table__thead-th-title-wrapper {
          justify-content: center;
        }
      }
    }
    &__tbody-td {
      &:first-child {
        text-align: center;
      }
      &:nth-child(4) {
        text-align: center;
      }
      &:last-child {
        text-align: center;
      }
    }
  }

  &__pagination {
    justify-content: center;
    padding: $base-gutter;
  }
}
